<template>
  <section class="section section bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="feature-detail">
            <div class="title-box text-center">
              <h2 class="fadeIn animated wow" data-wow-delay=".1s">{{$t("We commit")}}</h2>
              <div class="border"></div>
            </div>

            <p>{{$t("In all projects we are committed to the evolution and growth of the software, that is, we make all the changes, corrections and new functionalities with the same methodology and effort of the initial project, we believe that the software will always require changes as our clients grow, For this reason we commit ourselves to be an ally in the development of the project and in future updates.")}}</p>
          </div>
        </div>
        <div class="col-lg-6">
          <img
            src="../../assets/images/bg2.jpg"
            class="img-fluid fadeIn animated wow center-block"
            data-wow-delay=".2s"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Commitment"
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
.feature-detail .border {
  margin-left: auto !important;
}
</style>